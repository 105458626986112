import React, { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "../../../components";
import {
  AccountSwitchIcon,
  LogoIcon,
  LogoTextIcon,
} from "../../../components/icons";
import { ROUTES } from "../../../constants";
import { setActiveOrganization } from "../../../redux/actions";
import {
  getActiveOrganization,
  getOrganizations,
} from "../../../redux/selectors";
import { UserService } from "../../../services";
import AccountSelector from "./AccountSelector";

interface IFullLayoutHeaderProps {
  sidebarCollapsed: boolean;
  onToggleSidebar(): void;
}

const FullLayoutHeader: FC<IFullLayoutHeaderProps> = ({
  sidebarCollapsed,
  onToggleSidebar,
}) => {
  const [viewSelector, setViewSelector] = React.useState(false);
  const dispatch = useDispatch();
  const organizations = useSelector(getOrganizations);
  const activeOrganization = useSelector(getActiveOrganization);

  const organizationOptions = useMemo(
    () =>
      organizations
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => ({
          text: item.name,
          onClick() {
            UserService.modifyProfile({ default_org: item.uuid }, false).then(
              (data) => {
                dispatch(setActiveOrganization(item));
              }
            );
          },
        })),
    [organizations]
  );

  const dropdownWidth = useMemo(() => {
    let width = 130;
    const el = document.createElement("div");
    el.style.fontSize = "0.8125rem";
    el.style.fontWeight = "500";
    el.style.lineHeight = "16px";
    el.style.paddingLeft = "3rem";
    el.style.width = `${width}px`;
    document.body.append(el);

    for (const item of organizations) {
      el.textContent = item.name;
      while (el.clientHeight > 40) {
        width += 5;
        el.style.width = `${width}px`;
      }
    }
    el.remove();

    return width + 50;
  }, [organizations]);

  return (
    <div className="h-12 relative bg-blue-dark flex items-center">
      <div className="flex items-center flex-row-reverse lg:flex-row">
        <div className="w-14 md:w-58 flex items-center ">
          <Link className="inline-flex items-center" to={ROUTES.DASHBOARD}>
            <div className="w-14 flex justify-center items-center">
              <LogoIcon color="primary" size={36} />
            </div>
            <LogoTextIcon
              color="white"
              className="hidden md:block"
              size={120}
            />
          </Link>
        </div>

        <div className="w-14 flex justify-center items-center">
          <i
            className="fa fa-bars text-lg text-white lg:hidden cursor-pointer"
            onClick={onToggleSidebar}
          />
        </div>
      </div>

      <div className="px-5 ml-auto" data-cy="open-org-menu">
        <div
          className="flex flex-row items-center space-x-8 font-bold text-white px-4 cursor-pointer"
          onClick={() => setViewSelector(true)}
        >
          {activeOrganization ? activeOrganization.name : "SUDONUM"}
          <AccountSwitchIcon className="ml-5" color="primary" />
        </div>
      </div>
      <div className="absolute w-full h-full left-0 top-0 z-10 shadow-lg pointer-events-none" />
      {viewSelector && (
        <AccountSelector onClose={() => setViewSelector(false)} />
      )}
    </div>
  );
};

export default FullLayoutHeader;
