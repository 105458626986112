import React, { FC } from "react";
import { Button, Modal, StatusBadge } from "../../../../components";
import { ErrorCircleIcon } from "../../../../components/icons";
import { WhatsappAccountModel } from "../../../../utils/types";
import { formatDateTime, formatPhoneNumber } from "../../../../utils/helpers";

interface IAccountHealthProps {
    account: WhatsappAccountModel;
    onClose: () => void,
}

export const AccountHealth: FC<IAccountHealthProps> = ({
    account,
    onClose,
}) => {

    const onMetaClick = () => {
        window.open('https://www.facebook.com/business/help/896873687365001', 'blank_');
    }

    return (
        <Modal
            title={'Your Number Health'}
            size="md"
            contentClass="!bg-gray-f6 lg:px-9 lg:py-5"
            onClose={onClose}
            footerClass="!bg-gray-f6 lg:px-9 lg:py-5"
            footerComponent={
                <Button
                    color="primary"
                    className="rounded-full shadow-md !text-sm !font-semibold"
                    onClick={onMetaClick}
                >
                    Go to Meta
                </Button>
            }
        >
            <div className="p-6">
                <div className="flex flex-row">
                    <div>
                        <p className="text-blue !font-bold text-3xl mb-3">{formatPhoneNumber(account.whatsapp_number)}</p>
                        <p className="text-xs"><span className="font-bold ">Friendly Number Name</span> | {formatDateTime(account.created)}</p>
                    </div>
                    {account.number_health === "GREEN" &&
                        <StatusBadge
                            className="ml-auto text-xs h-8"
                            text="Healthy"
                            color="#8CDA55"
                        />
                    }
                    {account.number_health === "ORANGE" &&
                        <StatusBadge
                            className="ml-auto text-xs h-8"
                            text="At Risk"
                            color="#F6A734"
                        />
                    }
                    {account.number_health === "RED" &&
                        <StatusBadge
                            className="ml-auto text-xs h-8"
                            text="Blocked"
                            color="#F63434"
                        />
                    }
                </div>
                <div className="border-t-2 border-[#E5E5E5] my-6" />
                <div className="flex flex-row gap-3">
                    {account.number_health === "GREEN" &&
                        <>
                            <ErrorCircleIcon color={'#8CDA55'} size={30} />
                            <div>
                                <StatusBadge
                                    className="capitalize text-xs"
                                    text="Your number is currently healthy and requires no further action"
                                    color="#8CDA55"
                                />
                                <p className="text-xs mt-2">If you would like to see an in depth number health report, please view it on META by clicking on the button below</p>
                            </div>
                        </>
                    }
                    {account.number_health === "ORANGE" &&
                        <>
                            <ErrorCircleIcon color={'#F6A734'} size={30} />
                            <div>
                                <StatusBadge
                                    className="capitalize text-xs"
                                    text="Your number is currently at risk for being blocked by META"
                                    color="#F6A734"
                                />
                                <p className="text-xs mt-2">This can be <strong>avoided</strong>, Please click on the button below and go to META to view your in depth insights.</p>
                            </div>
                        </>
                    }
                    {account.number_health === "RED" &&
                        <>
                            <ErrorCircleIcon color={'#F63434'} size={30} />
                            <div>
                                <StatusBadge
                                    className="capitalize text-xs"
                                    text="Your number is being blocked by META"
                                    color="#F63434"
                                />
                                <p className="text-xs mt-2">Please urgently go to META to resolve this issue or email <a href="mailto:support@sudonum.com" target="_blank" rel="noreferrer" className="text-primary !font-semibold">support@sudonum.com</a></p>
                            </div>
                        </>
                    }
                </div>
            </div>
        </Modal>
    )
}