import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { Preloader } from "./providers";
import {
  MessageBoxContainer,
  SpinnerContainer,
  ToastContainer,
} from "./components";
import { IdleTimerProvider } from "./providers/InactivityProvider";

import FullLayout from "./layouts/FullLayout";
import { ROUTES } from "./constants";

import Login from "./pages/Auth/Login";
import ConfirmEmail from "./pages/Auth/ConfirmEmail";
import ActivateAccount from "./pages/Auth/ActivateAccount";
import ResetPassword from "./pages/Auth/ResetPassword";

import ConfirmTotp from "./pages/Auth/ConfirmTotp";
import OnboardRouting from "./pages/Onboard/routing";
import CallsRouting from "./pages/Calls/routing";
import MessagesRouting from "./pages/Messages/routing";
import NumbersRouting from "./pages/Numbers/routing";
import DeveloperRouting from "./pages/Developer/routing";
import SettingsRouting from "./pages/Settings/routing";
import WhatsappRouting from "./pages/Whatsapp/routing";
import Options from "./pages/Options";
import Dashboard from "./pages/Dashboard";
import DashboardRouting from "./pages/Dashboard/routing";
import InactivityAlert from "./components/InactivityAlert";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
});

const Pages = () => {
  const default_page = ROUTES.DASH.INDEX;

  return (
    <Preloader>
      <Route path={ROUTES.ONBOARD.PREFIX} component={OnboardRouting} />
      <IdleTimerProvider>
        <InactivityAlert />
        <FullLayout>
          <Switch>
            <Redirect path={ROUTES.DASHBOARD} to={default_page} />
            <Route path={ROUTES.CALLS.PREFIX} component={CallsRouting} />
            <Route path={ROUTES.MESSAGES.PREFIX} component={MessagesRouting} />
            <Route path={ROUTES.NUMBERS.PREFIX} component={NumbersRouting} />
            <Route path={ROUTES.DEVELOPER.PREFIX} component={DeveloperRouting} />
            <Route path={ROUTES.WHATSAPP.PREFIX} component={WhatsappRouting} />
            <Route path={ROUTES.SETTINGS.PREFIX} component={SettingsRouting} />
            {/*<Route path={ROUTES.OPTIONS.PREFIX} component={Options} />*/}
            <Route path={ROUTES.DASH.PREFIX} component={DashboardRouting} />
            <Redirect to={ROUTES.DASHBOARD} />
          </Switch>
        </FullLayout>
      </IdleTimerProvider>
    </Preloader>
  );
};

const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Switch>
        <Route path={ROUTES.AUTH.LOGIN} component={Login} />
        <Route path={ROUTES.AUTH.CONFIRM_EMAIL} component={ConfirmEmail} />
        <Route
          path={ROUTES.AUTH.VERIFY_TOKEN}
          component={ActivateAccount}
        />
        <Route
          path={ROUTES.AUTH.RESET_PASSWORD}
          component={ResetPassword}
        />
        <Route
          path={ROUTES.AUTH.CONFIRM_TOTP}
          component={ConfirmTotp}
        />
        {Pages()}
      </Switch>
    </Router>
    <ToastContainer />
    <MessageBoxContainer />
    <SpinnerContainer />
  </ThemeProvider>
)

export default App;
