import React from "react";
import { TwoFactorInput } from "../../../components/TwoFactorInput";
import { LogoIcon, LogoTextIcon } from "../../../components/icons";
import { ROUTES } from "../../../constants";
import AuthLayout from "../../../layouts/AuthLayout";
import { AuthService, ToastService } from "../../../services";

const ConfirmTotp = () => {
  const submitTFA = token => {
    AuthService.totpSession(token).then(() => {
      ToastService.success(<><span className="text-success mr-1">Successfully</span> sent your request.</>);
      window.location.href = ROUTES.DASHBOARD;
    }).catch((err) => {
      ToastService.error("Invalid token.");
    });
  };

  return (
    <AuthLayout contentClass="w-120">
      <div className="flex items-center text-blue-dark">
        <LogoIcon size={24} />
        <LogoTextIcon className="ml-1" size={100} />
      </div>

      <h1 className="text-40p text-primary font-bold mt-4">Please enter your OTP</h1>

      <p className="text-blue font-semibold text-lg mt-6">Enter the OTP from your Authenticator app.</p>
      <br />

      <TwoFactorInput secondary onComplete={token => { submitTFA(token) }} />
    </AuthLayout>
  );
};

export default ConfirmTotp;
