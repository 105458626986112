import { formatPaginatedResult, trimObject } from "../utils/helpers";
import { CurrentErrorModel } from "../utils/types";
import { HttpService } from "./http.service";

export class ErrorManagementService {
  static search(query = {}, showSpinner = true) {
    return HttpService.get(
      "/errors/tracking/",
      trimObject(query),
      {},
      showSpinner
    ).then((res) => formatPaginatedResult(CurrentErrorModel, res));
  }

  static find(issueID: string, showSpinner = true) {
    return HttpService.get(
      `/errors/tracking/${issueID}/`,
      {},
      {},
      showSpinner
    ).then((res) => new CurrentErrorModel(res));
  }
}
