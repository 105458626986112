import React, { useCallback, useState } from "react";
import { Modal, TextField } from "../../../components";
import { CheckCircleIcon, SearchIcon } from "../../../components/icons";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveOrganization,
  getOrganizations,
} from "../../../redux/selectors";
import { OrganizationModel } from "../../../utils/types";
import { UserService } from "../../../services";
import { setActiveOrganization } from "../../../redux/actions";

const AccountSelector: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const activeOrganization = useSelector(getActiveOrganization);
  const organizations = useSelector(getOrganizations);

  const setNewOrganization = (item: OrganizationModel) => {
    UserService.modifyProfile({ default_org: item.uuid }, false).then(
      (data) => {
        dispatch(setActiveOrganization(item));
      }
    );
    onClose();
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const filteredOrganizations = organizations.filter((org) =>
    org.name.toLowerCase().includes(search.toLowerCase())
  );
  // .slice(0, 4);

  return (
    <Modal title={"Switch Account"} size="md" onClose={onClose}>
      <div className="flex flex-col pb-5">
        <div className="py-5 font-medium">
          <p>Please select or search for an account from the following list.</p>
        </div>
        <div className="pb-5">
          <TextField
            fullWidth
            className="!rounded-full !bg-gray-100"
            icon={<SearchIcon size={16} color="primary" />}
            placeholder="Search for an account"
            clearable
            value={search}
            onChange={onSearch}
          />
        </div>
        <div>
          <div className="flex flex-row font-bold justify-between py-5 cursor-pointer border-b border-gray-200">
            {activeOrganization.name}
            <CheckCircleIcon className="mr-80" color={"blue"} />
          </div>
        </div>
        <div className="h-65 overflow-auto">
          {filteredOrganizations.map((org) => (
            <div
              onClick={() => setNewOrganization(org)}
              className="py-5 cursor-pointer border-b border-gray-200"
              key={org.uuid}
            >
              {org.name}
            </div>
          ))}
          {filteredOrganizations.length === 0 && search && (
            <p className="text-gray-500 text-center py-2">No results found</p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AccountSelector;
