import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getActiveOrganization } from "../../../redux/selectors";
import { ErrorManagementService } from "../../../services/issue.service";
import { DataTable, IDataTableColumn, TextField } from "../../../components";
import { CircularProgress, Tooltip } from "@mui/material";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { InfoCircleIcon, SearchIcon } from "../../../components/icons";
import { ROUTES } from "../../../constants";
import { CurrentErrorModel } from "../../../utils/types";
import { formatDateTime } from "../../../utils/helpers";
import { debounce } from "lodash";
import { ReactComponent as EmptyErrorsSVG } from "../../../assets/empty-page.svg";

export const CurrentErrors = () => {
  const rowsPerPage = 50;

  const organization = useSelector(getActiveOrganization);
  const history = useHistory();

  const [referenceId, setReferenceId] = useState<string>("");
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentErrors, setCurrentErrors] = useState<CurrentErrorModel[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState<string>("");

  const debouncedSetFilter = useCallback(
    debounce((filter) => setReferenceId(filter), 500),
    []
  );

  const onSearchChange = (search) => {
    setSearch(search);
    debouncedSetFilter(search);
  };

  const columns = useMemo<IDataTableColumn<CurrentErrorModel>[]>(() => {
    return [
      {
        title: "Date and Time",
        field: "time",
        tooltip: {
          title: "TODO",
        },
        render(row: CurrentErrorModel) {
          return formatDateTime(row.created);
        },
      },
      {
        title: "Error Type",
        field: "error_object_type",
        tooltip: {
          title: "TODO",
        },
        render(row: CurrentErrorModel) {
          return (
            <span className="capitalize">
              {row.error_object_type.replace(".", " ")}
            </span>
          );
        },
      },
      {
        title: "Reference ID",
        field: "reference_id",
        tooltip: {
          title: "The reference ID of the error",
        },
      },
      {
        title: "Description",
        field: "description",
        tooltip: {
          title: "TODO",
        },
        render(row: CurrentErrorModel) {
          return (
            <p>
              {row.customer_error_message}
              {/* <Link
                                className="text-primary capitalize !font-semibold ml-2 hover:cursor-pointer hover:!font-bold"
                                to={`${ROUTES.DEVELOPER.ISSUES.PREFIX}/${row.id}/`}
                            >How to fix it</Link> */}
            </p>
          );
        },
      },
      {
        render(row: CurrentErrorModel) {
          return (
            <div className="flex items-center justify-end gap-1.5">
              {row.isNew() && (
                <div className="rounded-md bg-[#FFE0E1] text-[#FE5A62] uppercase p-2 !font-bold max-w-fit">
                  New Error
                </div>
              )}
              <Tooltip title="View Details" arrow placement="top">
                <Link
                  data-cy={"webhook-edit"}
                  className="w-7.5 h-7.5 flex-center flex-shrink-0 bg-blue-lighter rounded-md ml-3 cursor-pointer"
                  to={`${ROUTES.DEVELOPER.CURRENT_ERRORS.DETAILS}/${row.id}/`}
                >
                  <InfoCircleIcon color="blue" size={18} />
                </Link>
              </Tooltip>
            </div>
          );
        },
      },
    ];
  }, [search, organization]);

  const loadCurrentErrors = useCallback((query) => {
    setLoading(true);
    ErrorManagementService.search(query, false).then((data) => {
      setCurrentErrors(data.results);
      setTotalCount(data.count);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const query: any = {
      page: page + 1,
      page_size: rowsPerPage,
    };

    if (referenceId) {
      query.reference_id = referenceId;
    }
    loadCurrentErrors(query);
  }, [referenceId, columns]);

  return (
    <>
      <div className="h-full flex flex-col px-4 pt-4">
        <TextField
          size="sm"
          fullWidth
          className="!rounded-full !bg-white mb-3"
          containerClass="w-80 ml-auto"
          value={search}
          icon={<SearchIcon size={16} color="primary" />}
          placeholder="Search Reference ID"
          clearable
          onChange={(text) => onSearchChange(text.split(" ").join(""))}
        />
        {loading ? (
          <div className="w-full h-full flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : currentErrors.length > 0 ? (
          <DataTable
            className="h-0 flex-1 mt-3"
            columns={columns}
            data={currentErrors}
            totalCount={totalCount}
            extLoading={loading}
            size="xs"
            stripped
            stickyHeader
            page={page}
            pagination
            selfPagination
            rowsPerPage={rowsPerPage}
            paginationClass="mr-16"
            onPaginationChange={(page) => setPage(page)}
          />
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <EmptyErrorsSVG />
            <div className="text-blue text-4xl font-bold py-5">Hooray!</div>
            <div className="pb-2">
              You have{" "}
              <span className="!font-semibold"> no current errors </span>
              {referenceId && <span> for this Reference ID.</span>}
            </div>
            <div>
              Head over to your{" "}
              <span
                className="text-primary !font-semibold cursor-pointer"
                onClick={() => history.push(ROUTES.WHATSAPP.CHAT_INTROS.INDEX)}
              >
                WhatsApp Leads
              </span>{" "}
              to see more information.
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CurrentErrors;
